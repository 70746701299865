<template>
  <div class="container">
    <JroHeaderNew></JroHeaderNew>
    <JroLinksNew></JroLinksNew>
    <JroProjectsNew></JroProjectsNew>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JroHeaderNew from "@/components/JroHeaderNew.vue"; // @ is an alias to /src
import JroLinksNew from "@/components/JroLinksNew.vue";
import JroProjectsNew from "@/components/JroProjectsNew.vue";

export default defineComponent({
  name: "JroHome",
  components: {
    JroHeaderNew,
    JroLinksNew,
    JroProjectsNew,
  },
});
</script>

<style scoped>
.container {
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns:
    [full-start] minmax(6rem, 1fr) [center-start] repeat(
      8,
      [col-start] minmax(min-content, 14rem) [col-end]
    )
    [center-end] minmax(6rem, 1fr) [full-end];
}
</style>
