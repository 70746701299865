import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-440aae71"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "language-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLocale('en')))
      }, "EN"),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLocale('de')))
      }, "DE")
    ]),
    _createVNode(_component_router_view)
  ]))
}