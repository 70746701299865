<template>
  <div>
    <div class="language-switch">
      <button @click="changeLocale('en')">EN</button>
      <button @click="changeLocale('de')">DE</button>
    </div>

    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  components: {
  },
  methods: {
    changeLocale(locale: string): void {
      this.$i18n.locale = locale;
    },
  },
});
</script>

<style lang="scss" scoped>
.language-switch {
    position: absolute;
    top: 1rem; /* Adjust as needed */
    right: 1rem; /* Adjust as needed */
    display: flex;
    gap: 0.5rem;
    z-index: 2;
}

.language-switch button {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #ccc;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    transition: background 0.3s;
}

.language-switch button:hover {
    background: rgba(255, 255, 255, 1);
}
</style>

<style lang="scss" global>
@import "global.scss";

*,
*::before,
*::after {
  // reset browser defaults (e.g. h1 margin)
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box; // border and padding are no longer added to total height and width of a box
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

  @media only screen and (max-width: $bp-largest) {
    font-size: 50%;
  }
}

body {
  font-family: "Lato", sans-serif;
  line-height: 1.6;
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: linear-gradient(to right, rgba(245, 245, 245, 0.8), rgba(163, 163, 163, 0.8));
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #555;
}

h2 {
    font-size: 3.2rem; /* No change needed */
    text-align: center;
    margin-bottom: 4rem; /* 40px */
    transition: transform 0.3s;
}

h2:hover {
    transform: scale(1.1);
}

.language-switch {
  position: absolute;
  top: 1rem;
  /* Adjust as needed */
  right: 1rem;
  /* Adjust as needed */
  display: flex;
  gap: 0.5rem;
}

.language-switch button {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  transition: background 0.3s;
}

.language-switch button:hover {
  background: rgba(255, 255, 255, 1);
}
</style>
