<template>
  <div class="project">
    <img v-if="image" :src="image" :alt="title" class="project-img" />
    <div class="project-info">
      <p class="year-status">{{ year }} | {{ inProgress ? this.$t("Project.inProgress") : this.$t("Project.finished") }}</p>
      <h3>{{ title }}</h3>
      <p>{{ subtitle }}</p>
      <a :href="link" class="open-link">{{ action }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "JroProject",
  props: {
    inProgress: Boolean,
    year: String,
    title: String,
    subtitle: String,
    action: String,
    link: String,
    image: String,
  },
});
</script>

<style lang="scss" scoped>
@import "../global.scss";

.project {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 2rem; /* 20px */
  border-radius: 1rem; /* 10px */
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1); /* 0 4px 8px */
  margin-bottom: 2rem; /* 20px */
  transition: transform 0.3s, box-shadow 0.3s;
  z-index: 10;

  &:hover {
    transform: translateY(-1rem); /* -10px */
    box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.2); /* 0 8px 16px */
  }
  
  &-img {
    width: 15rem; /* 150px */
    height: 15rem; /* 150px */
    border-radius: 1rem; /* 10px */
    margin-right: 2rem; /* 20px */
  }

  &-info {
    flex: 1;
  }
  
  .year-status {
    font-size: 1.4rem; /* 14px */
    color: #777;
  }

  h3 {
    font-size: 2.4rem; /* 24px */
    color: rgba(var(--color-primary-rgb), 1);
    margin: 1rem 0; /* 10px 0 */
  }

  .open-link {
    color: rgba(var(--color-primary-rgb), 1);
    font-weight: bold;
    display: inline-block;
    margin-top: 1rem; /* 10px */
    transition: color 0.3s;

    &:hover {
      color: #555;
    }
  }
}

/* Responsive Design */
@media (max-width: $bp-medium) {
  .project {
    flex-direction: column;
    align-items: flex-start;

    &-img {
      margin-bottom: 1.6rem; /* 16px */
    }
  }
}
</style>
