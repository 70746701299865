<template>
  <section class="projects">
    <h2>{{ $t('Home.privateProjects') }}</h2>
    <div class="projects-container">
      <JroProjectNew year="2022" :title="this.$t('Projects.mediaOrga.title')" image='images/mediaOrga.jpg'
        :subtitle="this.$t('Projects.mediaOrga.subtitle')" :action="this.$t('Projects.mediaOrga.action')"
        :link="this.$t('Projects.mediaOrga.link')">
      </JroProjectNew>

      <JroProjectNew year="2020" :title="this.$t('Projects.autocomplete.title')"
        :subtitle="this.$t('Projects.autocomplete.subtitle')" :action="this.$t('Projects.autocomplete.action')"
        :link="this.$t('Projects.autocomplete.link')">
      </JroProjectNew>

      <JroProjectNew year="2019" :title="this.$t('Projects.hhb.title')" :subtitle="this.$t('Projects.hhb.subtitle')"
        :action="this.$t('Projects.hhb.action')" :link="this.$t('Projects.hhb.link')">
      </JroProjectNew>

      <JroProjectNew year="2019" :title="this.$t('Projects.logger.title')"
        :subtitle="this.$t('Projects.logger.subtitle')" :action="this.$t('Projects.logger.action')"
        :link="this.$t('Projects.logger.link')">
      </JroProjectNew>

      <JroProjectNew year="2018" :title="this.$t('Projects.leanCanvas.title')" image='images/leanCanvas.jpg'
        :subtitle="this.$t('Projects.leanCanvas.subtitle')" :action="this.$t('Projects.leanCanvas.action')"
        :link="this.$t('Projects.leanCanvas.link')">
      </JroProjectNew>

      <JroProjectNew year="2017" :title="this.$t('Projects.visualObserver.title')" image='images/observer.jpg'
        :subtitle="this.$t('Projects.visualObserver.subtitle')" :action="this.$t('Projects.visualObserver.action')"
        :link="this.$t('Projects.visualObserver.link')">
      </JroProjectNew>

      <JroProjectNew year="2014" :title="this.$t('Projects.headphones.title')" image='images/master.jpg'
        :subtitle="this.$t('Projects.headphones.subtitle')" :action="this.$t('Projects.headphones.action')"
        :link="this.$t('Projects.headphones.link')">
      </JroProjectNew>

      <JroProjectNew year="2013" :title="this.$t('Projects.weatherStation.title')"
        :subtitle="this.$t('Projects.weatherStation.subtitle')" :action="this.$t('Projects.weatherStation.action')"
        :link="this.$t('Projects.weatherStation.link')">
      </JroProjectNew>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JroProjectNew from "@/components/JroProjectNew.vue";

export default defineComponent({
  name: "JroProjectsNew",
  components: {
    JroProjectNew,
  },
});
</script>

<style lang="scss" scoped>
@import "../global.scss";


.projects {
  grid-column: full-start / full-end;
  padding-top: 4rem;
  /* 40px */
  width: 100%;
  /* position: relative; */
  /* background: url('pattern.png') repeat; */
  background: linear-gradient(to right, rgba(var(--color-secondary-4-rgb), 1), rgba(var(--color-secondary-5-rgb), 1));
  /* z-index: -1; */
}

/* .projects::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(var(--color-secondary-4-rgb), 0.8), rgba(var(--color-secondary-5-rgb), 0.8));
    z-index: -1;
} */


.projects-container {
  padding: 4rem 2rem;
  /* 40px 20px */
  max-width: 120rem;
  /* No change needed */
  margin: 0 auto;
}

.projects h2 {
  color: white;
}
</style>
