<template>
  <header class="header">
    <section class="image-header">
      <div class="image-header-content">
        <div><img src="images/josch.jpg" alt="Profile Image" class="profile-img"></div>
        <div>
          <h1 class="name">Josch Rossa MSc</h1>
          <p class="job">Software Engineer</p>
          <!-- <p class="contact">Am Gangsteig 6 | 85051 Ingolstadt | jh.rossa@gmail.com</p> -->
        </div>
      </div>
    </section>
    <section class="intro">
      <div class="description">
        {{ $t("Summary.aboutMe") }}
        <a :href="this.$t('Summary.propOneLink')">{{ $t("Summary.propOne") }}</a>&nbsp;
        <a :href="this.$t('Summary.propTwoLink')">{{ $t("Summary.propTwo") }}</a>
      </div>
    </section>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "JroHeader",
});
</script>

<style lang="scss" scoped>
@import "../global.scss";

.header {
  grid-column: full-start / full-end;
}

.image-header {
  position: relative;
  background: url('~@/assets/pattern.png') repeat;
  color: black;
  padding: 3.2rem 0;
  text-align: center;
  height: 25.6rem;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.image-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(var(--color-primary-rgb), 0.8), rgba(var(--color-secondary-1-rgb), 0.8));
  z-index: 0;
}

.image-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  position: relative;
  z-index: 1;
}

.image-header-content .profile-img {
  width: 12.8rem;
  height: 12.8rem;

  border-radius: 50%;
  /* Creates the circle */
  object-fit: cover;
  /* Ensures the image covers the area without distorting ratio */
  border: 3px solid white;
  /* Optional: adds a white border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Optional: adds shadow for depth */
}

.image-header-content .name {
  font-size: 2.08rem;
  margin-top: 0rem;
}

.image-header-content .job {
  font-size: 1.4rem;
  margin-top: 0rem;
}

.image-header-content .contact {
  font-size: 1.4rem;
}

/* Intro Section */
.intro {
  position: relative;
  background: url('~@/assets/pattern.png') repeat;
  color: white;
  padding: 3.2rem 2rem;
  /* 32px 20px */
  text-align: center;
  height: 25.6rem;
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(var(--color-secondary-4-rgb), 0.8), rgba(var(--color-secondary-5-rgb), 0.8));
  z-index: 0;
}

.intro .description {
  font-size: 1.6rem;
  /* No change needed */
  max-width: 80rem;
  /* No change needed */
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 1.6rem 1.6rem;
  /* 0 16px */
  background-color: black;
  border-radius: 0.3125rem;
  /* 5px */
}

@media (max-width: $bp-medium) {
    .image-header, .intro {
        clip-path: none;
        height: auto;
    }

    .image-header-content, .intro .description {
        padding: 1.6rem; /* 16px */
    }

    .image-header h1 {
        font-size: 2.5rem; /* No change needed */
    }

    .image-header .name {
        font-size: 1.76rem; /* No change needed */
    }

    .image-header .contact {
        font-size: 1.4rem; /* No change needed */
    }
  }
</style>
