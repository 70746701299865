export default {
  "Summary": {
    "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passionate software engineer with 10+ years experience in developing applications. Convinced that software does not have an end in itself and therefore always motivated to start at a user journey. Proven expertise in building large systems composed of smaller ones with a reduced level of complexity."])},
    "propOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Restorative"])},
    "propOneLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gallup.com/cliftonstrengths/en/252323/restorative-theme.aspx"])},
    "propTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Activator"])},
    "propTwoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gallup.com/cliftonstrengths/en/252140/activator-theme.aspx"])}
  },
  "Home": {
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "privateProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private projects"])},
    "virtualIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Introduction"])}
  },
  "Project": {
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])}
  },
  "Projects": {
    "weatherStation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weather station"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on Arduino und RaspberryPi a weather station was developed. An overview of the system setup is linked below."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/WetterstationAufbau.pdf"])}
    },
    "headphones": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headphones with gesture recognition"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within the scope of a master thesis, a sensor based gesture recognition system was developed. Based on gathered training and test data, it is possible to perform correct recognition in up to 98 percent of all cases. Further details shows the abstact linked below."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/MA_rossa_abstract.pdf"])}
    },
    "visualObserver": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visual observer"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Raspberry Pi Zero W combined with a night vision camera was used to observe a power meter in an automated way. Additionally, meter readings were extracted from the images in a prototypic setup (OCR). For the extraction the google vision api has been used."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/Stromableser.pdf"])}
    },
    "leanCanvas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital \"lean canvas\""])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Together with my former colleague phibart, I implemented a digital version of the \"lean canvas\", which is a simplified \"business plan\" template created by Ash Maurya. The web application based on vue.js can be used for free. Just click the link below to try it out."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://rosbart.gitlab.io/lean_canvas/"])}
    },
    "logger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stackdriver logger"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A library to send simple logs to google cloud stackdriver from any javascript based web application. There are plenty of libraries that can be used when logging from a node.js based application, but I didn't find one logging from the frontend directly."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://gitlab.com/josros/stackdriver-logger-js"])}
    },
    "hhb": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborative account book"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For all who want to track their costs, I developed a collaborative house account book. The application saves the data of the user in google drive. Users can work on cost lists collaboratively, which brings up further use cases. The application is based on Vue.js."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hhb.rossa.dev"])}
    },
    "autocomplete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autocomplete Web Component"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on lit-elment, I developed a basic auto-completion Web Component."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.gitlab.io/autocomplete/"])}
    },
    "mediaOrga": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Organizer"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Electron-based application for organizing media files (images, videos). It enables quick tagging and sorting of media files in the file system. For a Windows build, feel free to contact me."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download (macOS)"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://e.pcloud.link/publink/show?code=XZqH3MZVHnclbdtWmVH9KSws1p8WhK29mX0"])}
    }
  }
}