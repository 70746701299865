import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d621f6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "project" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "project-info" }
const _hoisted_4 = { class: "year-status" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.image,
          alt: _ctx.title,
          class: "project-img"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.year) + " | " + _toDisplayString(_ctx.inProgress ? this.$t("Project.inProgress") : this.$t("Project.finished")), 1),
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1),
      _createElementVNode("a", {
        href: _ctx.link,
        class: "open-link"
      }, _toDisplayString(_ctx.action), 9, _hoisted_5)
    ])
  ]))
}