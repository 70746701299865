import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8a81d84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "projects" }
const _hoisted_2 = { class: "projects-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JroProjectNew = _resolveComponent("JroProjectNew")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('Home.privateProjects')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_JroProjectNew, {
        year: "2022",
        title: this.$t('Projects.mediaOrga.title'),
        image: "images/mediaOrga.jpg",
        subtitle: this.$t('Projects.mediaOrga.subtitle'),
        action: this.$t('Projects.mediaOrga.action'),
        link: this.$t('Projects.mediaOrga.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2020",
        title: this.$t('Projects.autocomplete.title'),
        subtitle: this.$t('Projects.autocomplete.subtitle'),
        action: this.$t('Projects.autocomplete.action'),
        link: this.$t('Projects.autocomplete.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2019",
        title: this.$t('Projects.hhb.title'),
        subtitle: this.$t('Projects.hhb.subtitle'),
        action: this.$t('Projects.hhb.action'),
        link: this.$t('Projects.hhb.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2019",
        title: this.$t('Projects.logger.title'),
        subtitle: this.$t('Projects.logger.subtitle'),
        action: this.$t('Projects.logger.action'),
        link: this.$t('Projects.logger.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2018",
        title: this.$t('Projects.leanCanvas.title'),
        image: "images/leanCanvas.jpg",
        subtitle: this.$t('Projects.leanCanvas.subtitle'),
        action: this.$t('Projects.leanCanvas.action'),
        link: this.$t('Projects.leanCanvas.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2017",
        title: this.$t('Projects.visualObserver.title'),
        image: "images/observer.jpg",
        subtitle: this.$t('Projects.visualObserver.subtitle'),
        action: this.$t('Projects.visualObserver.action'),
        link: this.$t('Projects.visualObserver.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2014",
        title: this.$t('Projects.headphones.title'),
        image: "images/master.jpg",
        subtitle: this.$t('Projects.headphones.subtitle'),
        action: this.$t('Projects.headphones.action'),
        link: this.$t('Projects.headphones.link')
      }, null, 8, ["title", "subtitle", "action", "link"]),
      _createVNode(_component_JroProjectNew, {
        year: "2013",
        title: this.$t('Projects.weatherStation.title'),
        subtitle: this.$t('Projects.weatherStation.subtitle'),
        action: this.$t('Projects.weatherStation.action'),
        link: this.$t('Projects.weatherStation.link')
      }, null, 8, ["title", "subtitle", "action", "link"])
    ])
  ]))
}