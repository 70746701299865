<template>
  <section class="links">
    <h2>{{ $t('Home.links') }}</h2>
    <div class="links-container">
      <a href="https://gitlab.com/josros" class="link">Gitlab</a>
      <a href="https://medium.com/@jh.rossa" class="link">Medium</a>
      <a href="https://www.linkedin.com/in/josef-rossa-213137115/" class="link">LinkedIn</a>
      <a href="/Introduction" class="prominent-link">{{ $t("Home.virtualIntro") }}</a>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "JroLinksNew",
});
</script>
<style lang="scss" scoped>
@import "../global.scss";

.links {
  grid-column: center-start / center-end;
  padding: 4rem 2rem 6rem 2rem; // 40px 20px 60px 20px
  text-align: center;

  h2 {
    color: black;
  }
}

.links-container {
  display: flex;
  justify-content: center;
  gap: 3rem; // 30px
}

.link {
  background-color: #fff;
  padding: 1rem 2rem; // 10px 20px
  border-radius: 0.5rem; // 5px
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
}

.link:hover {
  transform: translateY(-0.5rem); // -5px
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.2);
}

.prominent-link {
  display: inline-block;
  padding: 1rem 2rem; // 10px 20px
  border-radius: 0.5rem; // 5px
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--color-primary-rgb), 1); // Primary color
  color: white; // Text color
  font-weight: bold; // Bold text
  text-align: center; // Center the text
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; // Smooth transitions
}

.prominent-link:hover {
  background-color: rgba(var(--color-secondary-1-rgb), 1); // Secondary color on hover
  transform: translateY(-0.4rem); // Slight lift on hover
  box-shadow: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.3); // Enhanced shadow on hover
}

@media (max-width: $bp-medium) {
  .links-container {
        flex-direction: column;
    }
  }
</style>
