export default {
  "Summary": {
    "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passionierter Software Entwickler mit 10+ Jahren Erfahrung in der Entwicklung von Applikationen. Überzeugt, dass Software kein Selbstzweck ist und deshalb stets motiviert vom Anwender her zu denken. Nachweisliche Erfahrung in der Entwicklung großer Systeme, bestehend aus kleinen Systemen mit einem reduzierten Komplexitätsumfang. "])},
    "propOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Wiederherstellung"])},
    "propOneLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gallup.com/cliftonstrengths/de/253157/Thema-Wiederherstellung.aspx"])},
    "propTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["#Tatkraft"])},
    "propTwoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gallup.com/cliftonstrengths/de/252758/Thema-Tatkraft.aspx"])}
  },
  "Home": {
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "privateProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Projekte"])},
    "virtualIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelle Vorstellung"])}
  },
  "Project": {
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Ausbau"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])}
  },
  "Projects": {
    "weatherStation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wetterstation"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Basis von Arduino und RaspberryPi habe ich eine Wetterstation entwickelt. Den Aufbau finden Sie unten verlinkt."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/WetterstationAufbau.pdf"])}
    },
    "headphones": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfhörer mit Gestenerkennung"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Rahmen meiner Masterarbeit habe ich ein sensorbasiertes Gestenerkennungssystem erstellt. Basierend auf den akquirierten Trainings- und Testdaten unterscheidet das System acht definierte Gesten in bis zu 98 Prozent der Testfälle korrekt. Weitere Informationen zeigt der unten verlinkte Abstract."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/MA_rossa_abstract.pdf"])}
    },
    "visualObserver": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zählerstands-Observator"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Raspberry Pi Zero W wurde mit einer Nachtsichtkamera kombiniert, um automatisiert Zählerstände zu observieren. In einem prototypischen Aufbau, konnten die Zählerstände aus dem Bild extrahiert werden (OCR). Für die Extraktion wurde die google vision api verwendet."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.github.io/documents/Stromableser.pdf"])}
    },
    "leanCanvas": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitales \"lean canvas\""])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammen mit meinem früheren Kollegen phibart haben wir eine digitale Version des \"lean canvas\" implementiert. Dabei handelt es sich um ein vereinfachtes \"business plan\" Template das Ash Maurya entwickelt hat. Die auf vue.js basierende Webapplikaiton ist kostenlos nutzbar. Probieren Sie es einfach aus."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://rosbart.gitlab.io/lean_canvas/"])}
    },
    "logger": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stackdriver Logger"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dabei handelt es sich um eine Library mit der man ganz einfach aus einer Javascript basierten Webappliaktion gegen Google Cloud Stackdriver loggen kann. Es gibt viele Libraries, die sowas aus dem Backend machen (z.B. für node.js). Für das direkte Loggen aus dem Frontend habe ich jedoch nichts gefunden."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://gitlab.com/josros/stackdriver-logger-js"])}
    },
    "hhb": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaboratives Haushaltsbuch"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für alle die den Überblick über ihre Ausgaben behalten möchten, habe ich ein digitales Haushaltsbuch entwickelt. Das Haushaltsbuch speichert die Daten im Benutzerkontext in Google Drive. Benutzer können auch gemeinsam an Kostenlisten arbeiten, wodurch sich weitere Anwendungsfälle ergeben. Die Applikation basiert auf vue.js und der Login wurde von Google authorisiert."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://hhb.rossa.dev"])}
    },
    "autocomplete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Component zur Autovervollständigung"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Basis von lit-elment, habe ich eine Web Component zur Autovervollständigung entwickelt."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://josros.gitlab.io/autocomplete/"])}
    },
    "mediaOrga": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Organizer"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Electron basierte Applikation zur Organisation von Medienfiles (Bilder, Videos). Ermöglicht schnelles Tagging und Sortierung von Medienfiles im Filesystem. Für einen Windows build, schreibe mich gerne an."])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download (macOS)"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://e.pcloud.link/publink/show?code=XZqH3MZVHnclbdtWmVH9KSws1p8WhK29mX0"])}
    }
  }
}